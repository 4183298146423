import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { navigate } from "gatsby"
import Seo from "../components/seo"
import {Container} from "./style"

const PAD = '8vh'


const MediaContainer = styled.div`
  width: calc(60% - ${PAD} - ${PAD});
  padding: ${PAD};
  height: calc(100vh - ${PAD} - ${PAD});
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > div {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`


const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-direction: column;
  margin: 4vh;
  font-size: 1.3em;
  line-height: 1.4em;
  h1 {
    font-size: 1em;
    font-weight: normal;
  }
  p, div {
    color: #666;
  }
  p:not(:last-child) { margin-bottom:1em; }
`


const DetailContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  @media (max-width: 599px) {
    flex-direction: column-reverse;
    ${MediaContainer} {
      width: calc(100% - ${PAD} - ${PAD});
      height: auto;
    }
    ${TextContainer} {
      justify-content: flex-start;
    }
  }
  
`


const Nav = styled.div`
  margin: 4vh;
  position: absolute;
  top: 0;
  left: 0;
  a svg:hover {
    opacity: 0.5;
  }
  svg {
    height: 1.6em;
    width: 1.6em;
    top: .15em;
    position: relative;
  }
`

const AboutContainer = styled.div`
  flex: 1;
  display: flex;
  
`

const FullCellMedia = ({post}) => {
  if (post.frontmatter.featuredVideo) {
    return (
      <video playsInline autoPlay muted controls loop poster={post.frontmatter.featuredImage.childImageSharp.fixed.src} src={post.frontmatter.featuredVideo.publicURL}>
      </video>
    )
  } else {
    return (
      <Img
        style={{ maxHeight: "76vh" }}
        imgStyle={{ objectFit: "contain" }}
        fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
      />
    )
  }
}


export default function Template({ data }) {
  const { markdownRemark: post } = data
  return (
    <Container>
      <Seo title={post.frontmatter.title} description={post.frontmatter.subline || post.excerpt} image={post.frontmatter.featuredImage.childImageSharp.fixed.src} />

      <DetailContainer>
        <TextContainer>
          
            <h1>
              {post.frontmatter.title}
            </h1>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            
        </TextContainer>
        <MediaContainer>
          <FullCellMedia post={post} />
        </MediaContainer>  
          


      </DetailContainer>
      <Nav>
            <a href="/">
              <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 19L22 1" stroke="black" strokeWidth="2.5"/>
                <path d="M22 19L4 1" stroke="black" strokeWidth="2.5"/>
              </svg>
            </a>
          </Nav>
    </Container>
  )
}
export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      id
      frontmatter {
        title
        path
        date
        borderless
        featuredVideo {
          publicURL
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 800) {
              src
              srcWebp
            }
          }
        }
        ratio
      }
      excerpt(pruneLength: 500)
    }
  }
`